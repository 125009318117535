import { useEffect, useRef } from "react";

interface Props {
  stream?: MediaStream;
  height?: number
  width?: number,
  onSize?: (data: any) => void
}

export const Video = ({ stream, height, width, onSize }: Props) => {
  const ref = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (ref.current && stream) {
      //@ts-ignore
      ref.current.srcObject = stream;
      ref.current.onloadedmetadata = () => {
        onSize?.({ width: ref.current!.videoWidth, height: ref.current!.videoHeight })
      }
    }
  }, [stream]);
  if (!stream)
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
      />
    );
  return (
    <video
      playsInline
      ref={ref as any}
      autoPlay
      muted
      height={height}
      width={width}
    ></video>
  );
};
