type StreamHandler = (stream: MediaStream[]) => void;
type ConnectionHandler = (connected: boolean) => void;
type CandidateHandler = (candidate: any) => void;

export interface CallOptions {
  numberOfVideoTransceivers?: number;
}

export default class VideoConnection {
  protected connection: RTCPeerConnection;
  protected localStreams: MediaStream[] = [];
  public remoteStreams: MediaStream[] = [];

  protected localIceCandidates: any[] = [];
  protected remoteIceCandidates: RTCIceCandidate[] = [];

  protected remoteStreamHandlers: StreamHandler[] = [];
  protected connectionHandlers: ConnectionHandler[] = [];
  protected candidateHandler: CandidateHandler | null = null;

  protected negotiationNeeded: Promise<void>;

  protected connected = false;

  public id?: string;

  constructor() {
    this.connection = new RTCPeerConnection({
      iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
    });
    this.initRTCConnection();
    this.negotiationNeeded = new Promise((resolve) => {
      this.connection.addEventListener("negotiationneeded", (event: any) => {
        resolve();
      });
    });
  }

  protected initRTCConnection() {
    this.connection.addEventListener("icecandidate", (event: any) => {
      if (event.candidate) {
        if (this.candidateHandler) {
          this.candidateHandler(event.candidate);
        } else {
          this.localIceCandidates.push(event.candidate);
        }
      }
    });
    this.connection.addEventListener("track", (event: any) => {
      const stream = new MediaStream();
      stream.addTrack(event.track);
      this.remoteStreams.push(stream);
      console.log("TRACK");
      for (const handler of this.remoteStreamHandlers) {
        handler(this.remoteStreams);
      }
    });

    this.connection.addEventListener("connectionstatechange", (event: any) => {
      switch (this.connection.connectionState) {
        case "closed":
        case "disconnected":
        case "failed":
          if (this.connected) {
            this.connected = false;
            for (const handler of this.connectionHandlers) {
              handler(false);
            }
          }
          break;
        case "connected":
          this.connected = true;
          for (const handler of this.connectionHandlers) {
            handler(true);
          }
          break;
      }
    });
  }

  protected addTracks() {
    if (this.localStreams.length) {
      this.localStreams
        .filter((stream) => !!stream)
        .forEach((stream) =>
          stream.getTracks().forEach((track: any) => {
            console.log("add track", stream);
            this.connection.addTrack(track, stream);
          })
        );
    }
  }

  protected addTransceivers(numberOfVideoTransceivers?: number) {
    console.log("add transceivers", numberOfVideoTransceivers);
    if (!numberOfVideoTransceivers) return;
    for (let i = 0; i < numberOfVideoTransceivers; i++) {
      this.connection.addTransceiver("video", {});
    }
  }

  public addIceCandiate(candidate: any) {
    const iceCandidate = new RTCIceCandidate(candidate);
    if (this.connection.remoteDescription) {
      this.connection.addIceCandidate(iceCandidate);
      return;
    }
    this.remoteIceCandidates.push(iceCandidate);
  }

  public async startCall(options: CallOptions = {}) {
    console.log("starting call", options);
    this.addTracks();
    this.addTransceivers(options.numberOfVideoTransceivers);
    try {
      await this.negotiationNeeded;
      return this.createOffer(options);
    } catch (err) {
      console.error(err);
    }
  }

  async setMaxBitrate(bitrate: number) {
    // Get the video sender
    const sender = this.connection.getSenders().find(s => s.track && s.track.kind === "video");
    if (!sender) {
      console.warn("No video sender found");
      return;
    }

    // Get the current parameters
    let parameters = sender.getParameters();
    console.log('parameters', parameters)
    if (!parameters.encodings) {
      parameters.encodings = [{}];
    }

    // Set max bitrate (in bps)
    parameters.encodings[0].maxBitrate = bitrate; // Example: 2Mbps (2000000 bps)
    parameters.encodings[0].maxFramerate = 15
    parameters.encodings[0].networkPriority = 'high'
    parameters.encodings[0].priority = 'high'
    // Apply the updated parameters
    await sender.setParameters(parameters);
    console.log("Max bitrate set to:", bitrate);
  }

  async setPreferredCodec(preferredCodec: string) {
    const transceiver = this.connection.getTransceivers().find(t => t.sender && t.sender.track?.kind === "video");
    if (!transceiver) {
      console.warn("No video transceiver found");
      return;
    }

    // Get available codecs
    const codecs = RTCRtpSender.getCapabilities("video")?.codecs;

    // Filter and prioritize the preferred codec
    const preferredCodecs = codecs?.filter(codec => codec.mimeType.includes(preferredCodec)) ?? [];

    if (preferredCodecs.length > 0) {
      transceiver.setCodecPreferences(preferredCodecs);
      console.log("Preferred codec set to:", preferredCodec);
    } else {
      console.warn("Preferred codec not found, available codecs:", codecs);
    }
  }

  public async createOffer(options: CallOptions = {}) {
    this.remoteStreams = [];
    this.remoteStreamHandlers = [];
    try {
      const offer = await this.connection.createOffer({
        offerToReceiveVideo: true,
      });
      await this.connection.setLocalDescription(offer);
      return offer;
    } catch (err) {
      console.error(err);
    }
  }

  public async handleOffer(offer: any) {
    console.log("handle offer", offer);
    await this.connection.setRemoteDescription(
      new RTCSessionDescription(offer)
    );
    this.addTracks();
    //this.setPreferredCodec("H264");
    const answer = await this.connection.createAnswer();
    if (answer) {
      await this.connection.setLocalDescription(answer);
      await this.setMaxBitrate(10000000);
      for (const c of this.remoteIceCandidates) {
        this.connection.addIceCandidate(c);
      }
      this.remoteIceCandidates = [];
      return answer;
    }
  }

  public async handleAnswer(answer: any) {
    console.log("handle answer", answer);
    try {
      await this.connection.setRemoteDescription(
        new RTCSessionDescription(answer)
      );
      for (const c of this.remoteIceCandidates) {
        this.connection.addIceCandidate(c);
      }
      this.remoteIceCandidates = [];
    } catch (err) {
      console.error(err);
    }
  }

  public async stopCall() {
    this.connection.close();
    this.remoteStreams = [];
    for (const handler of this.remoteStreamHandlers) {
      handler(this.remoteStreams);
    }
  }

  public async setVideoStreams(streams: MediaStream[]) {
    console.log("setVideoStreams", this.connected, streams);
    this.localStreams = streams;
  }

  private replaceStream(stop: MediaStream, start: MediaStream) {
    console.log("Replacing", stop.id, start.id);
    const stopTrack = stop.getVideoTracks()[0];
    let sender = this.connection
      .getSenders()
      .find((s) => s.track?.id === stopTrack.id);
    if (sender) {
      console.log("Found sender");
      sender.replaceTrack(start.getVideoTracks()[0]);
    }
  }

  private removeStream(stream: MediaStream) {
    console.log("Removing", stream.id);
    this.localStreams.splice(this.localStreams.indexOf(stream), 1);
    const stopTrack = stream.getVideoTracks()[0];
    let sender = this.connection
      .getSenders()
      .find((s) => s.track?.id === stopTrack.id);
    if (sender) {
      console.log("Found sender");
      this.connection.removeTrack(sender);
    }
  }

  private addStream(stream: MediaStream) {
    console.log("Adding", stream.id);
    stream.getTracks().forEach((track: any) => {
      console.log("add track", stream);
      this.connection.addTrack(track, stream);
    });
  }

  public onRemoteStream(streamHandler: StreamHandler) {
    if (this.remoteStreams) {
      streamHandler(this.remoteStreams);
    }
    this.remoteStreamHandlers.push(streamHandler);
  }

  public onConnectionStateChanged(connectionHandler: ConnectionHandler) {
    this.connectionHandlers.push(connectionHandler);
  }

  public onIceCandidate(candidateHandler: CandidateHandler) {
    this.candidateHandler = candidateHandler;
    for (const candidate of this.localIceCandidates) {
      this.candidateHandler(candidate);
    }
  }
}
