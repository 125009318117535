"use client"
import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useSpring, animated } from '@react-spring/web'
import { createUseGesture, dragAction, pinchAction } from '@use-gesture/react'
import { Video } from './Video';
import { useItemSize } from './Gallery';

const useGesture = createUseGesture([dragAction, pinchAction])

function getBounds(containerSize: number, w: number, h: number, degrees: number) {
  const radians = degrees * (Math.PI / 180);
  const cosY = Math.abs(Math.cos(radians));
  const sinY = Math.abs(Math.sin(radians));

  const newWidth = w * cosY + h * sinY;
  const newHeight = w * sinY + h * cosY;
  return { x: (newWidth - containerSize) / 2, y: (newHeight - containerSize) / 2 };
}

export interface State {
  rotation: number,
  scale: number,
  x: number,
  y: number
}

interface Props {
  onUpdate?: (state: State) => void,
  style?: React.CSSProperties,
  state?: State
  stream?: MediaStream
}

const defaultState: State = {
  rotation: 0,
  scale: 1,
  x: 0,
  y: 0
}

export const RotatedVideo = ({ stream, style: containerStyle, onUpdate, state = defaultState }: Props) => {
  console.log('VIDEO', state)
  useEffect(() => {
    const handler = (e: Event) => e.preventDefault()
    document.addEventListener('gesturestart', handler)
    document.addEventListener('gesturechange', handler)
    document.addEventListener('gestureend', handler)
    return () => {
      document.removeEventListener('gesturestart', handler)
      document.removeEventListener('gesturechange', handler)
      document.removeEventListener('gestureend', handler)
    }
  }, [])


  const containerSize = useItemSize()
  console.log('CONTAINERSIZE', containerSize)
  console.log('ROTATION', state.rotation)

  const [bounds, setBounds] = useState({ left: 0, right: 0, top: 0, bottom: 0 })
  const [videoSize, setVideoSize] = useState({ height: containerSize, width: containerSize })
  const aspectRatio = videoSize.width / videoSize.height

  const renderedVideoSize = useMemo(() => {
    let renderedVideoSize = {
      height: containerSize,
      width: aspectRatio * containerSize
    }
    if (aspectRatio < 1) {
      renderedVideoSize = {
        height: containerSize / aspectRatio,
        width: containerSize
      }
    }
    return renderedVideoSize
  }, [videoSize, containerSize])

  useEffect(() => {
    const { x, y } = getBounds(containerSize, renderedVideoSize.width * state.scale, renderedVideoSize.height * state.scale, state.rotation)
    setBounds({ left: -x, right: x, top: -y, bottom: y })
  }, [renderedVideoSize, containerSize])



  const [style, api] = useSpring(() => ({
    x: 0,
    y: 0,
    scale: state.scale,
    rotateZ: state.rotation,
  }))



  useEffect(() => {
    api.start({
      x: state.x * renderedVideoSize.width,
      y: state.y * renderedVideoSize.height,
      scale: state.scale,
      rotateZ: state.rotation,
    })
    const { x, y } = getBounds(containerSize, renderedVideoSize.width * state.scale, renderedVideoSize.height * state.scale, state.rotation)

    setBounds({ left: -x, right: x, top: -y, bottom: y })
  }, [state.x, state.y, state.scale, state.rotation, api, renderedVideoSize])

  const ref = React.useRef<HTMLDivElement>(null)


  useGesture(
    {
      onDrag: ({ pinching, cancel, offset: [x, y], ...rest }) => {
        if (pinching) return cancel()
        api.start({ x, y, immediate: true })
        onUpdate?.({
          x: x / renderedVideoSize.width,
          y: y / renderedVideoSize.height,
          scale: style.scale.get(),
          rotation: style.rotateZ.get()
        })
      },
      onPinch: ({ origin: [ox, oy], first, movement: [ms], offset: [s, a], memo, last }) => {
        if (first) {
          const { width, height, x, y } = ref.current!.getBoundingClientRect()
          const tx = ox - (x + width / 2)
          const ty = oy - (y + height / 2)
          memo = [style.x.get(), style.y.get(), tx, ty]
        }

        if (last) {
          const { x, y } = getBounds(containerSize, renderedVideoSize.width * s, renderedVideoSize.height * s, a)

          setBounds({ left: -x, right: x, top: -y, bottom: y })
        }



        const x = memo[0] - (ms - 1) * memo[2]
        const y = memo[1] - (ms - 1) * memo[3]
        const rotateZ = a + state.rotation
        const scale = s * state.scale
        api.start({ scale, rotateZ, x, y, immediate: true })
        onUpdate?.({
          rotation: rotateZ,
          scale,
          x: x / renderedVideoSize.width,
          y: y / renderedVideoSize.height,
        })
        return memo
      },
    },
    {
      target: ref,
      drag: {
        from: () => [style.x.get(), style.y.get()],
        bounds
      },
      pinch: { scaleBounds: { min: 1 / state.scale, max: 2 / state.scale }, rubberband: true },
    }
  )

  return (
    <div style={{ display: "flex", justifyContent: "center", flexDirection: aspectRatio > 1 ? 'row' : 'column', height: containerSize, width: containerSize, overflow: "hidden", ...containerStyle }}>
      <animated.div ref={ref} style={{ ...style, flexGrow: 1, aspectRatio, touchAction: 'none' }}>
        <Video
          stream={stream}
          height={renderedVideoSize.height}
          width={renderedVideoSize.width}
          onSize={setVideoSize}
        />
      </animated.div>
    </div>
  )
}
