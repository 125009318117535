import { State } from "./components/RotatedVideo";

const TOKEN_KEY = "token";

export default {
  getToken: () => localStorage.getItem(TOKEN_KEY),
  setToken: (token: string) => localStorage.setItem(TOKEN_KEY, token),
  removeToken: () => localStorage.removeItem(TOKEN_KEY),
  setState: (id: string, state: State) =>
    localStorage.setItem(`state-${id}`, JSON.stringify(state)),
  getState: (id: string) =>
    JSON.parse(localStorage.getItem(`state-${id}`) ?? "{}"),
  setCameraIndexes: (indexes: number[]) =>
    localStorage.setItem("cameraIndexes", JSON.stringify(indexes)),
  getCameraIndexes: () => JSON.parse(localStorage.getItem("cameraIndexes") ?? "[]"),
};
