import { useRef, useState } from "react";
import { CameraManager } from "../../../cameras/CameraManager";
import { Camera } from "../../../interfaces";
import { useCameraService } from "../../../hooks/useCameraService";
import { Options } from "../../../cameras/CameraService";
import { Socket } from "socket.io-client";
import { useQueryParameter } from "../../../hooks/useQueryParameter";
import { State } from "../../../components/RotatedVideo";

const options: Options = {
  persistenceEnabled: true,
};

export const useLocalCameraManager = () => {
  const manager = useRef<CameraManager>();
  const cameraService = useCameraService(options);
  const [cameras, setCameras] = useState<Camera[]>([]);
  const [selectedCameraIds, setSelectedCameraIds] = useState<string[]>([]);
  const [selectedCameras, setSelectedCameras] = useState<Camera[]>([]);

  const init = async (socket: Socket) => {
    manager.current = new CameraManager(cameraService);
    manager.current.onCameraUpdate(() => {
      setSelectedCameras(cameraService.selectedCameras);
    });
    await manager.current!.init();
    await cameraService.startCameras();
    setCameras(cameraService.cameras);
    setSelectedCameraIds(cameraService.selectedCameraIds);
    setSelectedCameras(cameraService.selectedCameras);

    await manager.current!.connect(socket);
  };

  const selectCameras = async (cameraIds: string[]) => {
    console.log('Foo', cameraIds)
    setSelectedCameraIds(cameraIds);
    await manager.current!.selectCameras(cameraIds);
    setSelectedCameras(cameraService.selectedCameras);
  };

  const onUpdate = (id: string, index: number, state: State) => {
    manager.current?.updateState(id, index, state);
  };

  const disconnect = async () => {
    await manager.current?.disconnect()
    cameraService.stopCameras()
  }

  return {
    cameras,
    selectedCameras,
    selectCameras,
    selectedCameraIds,
    init,
    onUpdate,
    disconnect,
  };
};
