import { RotatedVideo, State } from "./RotatedVideo";

interface Camera {
  stream?: MediaStream;
  state?: State;
  onUpdate?: (state: State) => void;
}

interface RemoteCameraProps {
  cameras: Camera[]
}


export const RemoteCamera = ({ cameras }: RemoteCameraProps) => (
  <>
    {cameras.map((camera, index) => (
      <RotatedVideo key={index} state={camera.state} onUpdate={camera.onUpdate} stream={camera.stream} />
    ))}
  </>
)