import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";


export const useQueryParameter = (name: string) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const param = useRef<string>();
  const navigate = useNavigate();
  const p = searchParams.get(name);
  if (p) {
    param.current = p;
  }
  useEffect(() => {
    if (param) {
      /*navigate(
        { pathname: location.pathname, search: '' },
        { replace: true }
      );*/
    }
  }, [location.pathname, navigate]);
  return param.current;
};
