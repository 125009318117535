import { ConnectionManager, Signaling } from "../connection";
import { Socket } from "socket.io-client";
import storage from "../storage";
import { CameraService } from "./CameraService";
import { State } from "../components/RotatedVideo";

export type Status = "disconnected" | "connecting" | "connected" | "scanning";

export class CameraManager {
  private manager?: ConnectionManager;
  private socket?: Socket;
  private cameraUpdateListener?: () => void;

  constructor(private cameraService: CameraService) { }

  init = async () => {
    await this.cameraService.init();
  };

  onCameraUpdate = (listener: () => void) => {
    this.cameraUpdateListener = listener;
  };

  connect = async (socket: Socket) => {
    this.socket = socket;
    this.manager = new ConnectionManager(new Signaling(socket));
    for (const camera of this.cameraService.selectedCameras) {
      this.manager.addVideoStream(camera.stream!);
    }
    this.manager.onStateUpdate((peerId, state) => {
      this.cameraService.updateCameraState(state);
      this.cameraUpdateListener?.();
    });
    this.manager.onConnection((connection) => {
      for (let i = 0; i < this.cameraService.selectedCameras.length; i++) {
        connection?.updateState({
          cameraIndex: i,
          ...this.cameraService.selectedCameras[i].state,
        });
      }
    });
    if (this.cameraService.selectedCameras.length > 0) {
      this.socket?.emit("set cameras", {
        count: this.cameraService.selectedCameras.length,
      });
    }
    this.manager.waitForOffers();
  };

  async selectCameras(cameraIds: string[]) {
    console.log('Foo2', cameraIds)
    const streams = await this.cameraService.selectCameras(cameraIds);
    await this.manager?.setVideoStreams(streams);
    if (this.cameraService.selectedCameras.length > 0) {
      this.socket?.emit("set cameras", {
        count: this.cameraService.selectedCameras.length,
      });
    }
  }

  disconnect = async () => {
    console.log("disconnecting");
    if (this.manager) {
      for (const connection of this.manager.connections) {
        await this.manager.stopCall(connection.id!);
      }
      this.manager = undefined;
      console.log("calls stopped");
    }
    this.socket?.disconnect();
  };

  updateState = (id: string, index: number, state: State) => {
    console.log('SAVE', id, state)
    storage.setState(id, state);
    this.cameraService.updateCameraState({
      cameraIndex: index,
      ...state
    });
    this.manager?.connections.forEach((connection) => {
      connection.updateState({
        cameraIndex: index,
        ...state
      });
    });
  };
}
