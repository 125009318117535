import React, { useEffect } from "react";
import { useState } from "react";
import QRCode from "qrcode.react";
import { cameraApi } from "../../../api";

const RegisterRemoteCamera = () => {
  const [qrCode, setQRCode] = useState<string>();

  useEffect(() => {
    registerCamera();
    return () => {
      cameraApi.disallowJoin();
    };
  }, []);

  const registerCamera = async () => {
    try {
      const { data } = await cameraApi.allowJoin();
      setQRCode(
        `${process.env.REACT_APP_CAMERA_URL}?code=${encodeURIComponent(
          data.token
        )}`
      );
    } catch (err) { }
  };

  if (qrCode) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
          margin: "20px",
          backgroundColor: "white",
        }}
      >
        <p
          style={{
            marginBottom: "20px",
            textAlign: "center",
            color: "#000000",
          }}
        >
          Halte diesen QR-Code in die Kamera, die registriert werden soll
        </p>
        <QRCode value={qrCode} size={300} />
      </div>
    );
  }

  return null;
};

export default RegisterRemoteCamera;
